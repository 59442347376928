//
// _authentication.scss
//


.auth-page-wrapper {
    .auth-page-content {
        padding-bottom: $footer-height;
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .footer {
        left: 0;
        background-color: transparent;
        color: var(--#{$prefix}body-color);
    }
}

.auth-one-bg-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 575.98px) {
        height: 280px;
    }
}

.auth-one-bg {
    // background-image: url("../../images/authbg.png");
    background-position: center;
    background-size: cover;
    background-color: #fff;

}

.auth-one-bg2 {
    background-image: url("../../images/authbg-resize.png");
    background-position: center;
    background-size: cover;

}

// auth-pass-inputgroup

.auth-pass-inputgroup {
    input[type="text"]+.btn .ri-eye-fill {
        &:before {
            content: "\ecb6";
        }
    }
}

.particles-js-canvas-el {
    position: relative;
}

// signin card title

.signin-other-title {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed var(--#{$prefix}border-color);
        top: 10px;
    }

    .title {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: var(--#{$prefix}card-bg-custom);
        padding: 2px 16px;
    }
}

// Auth cover

.auth-bg-cover {
    background: $auth-bg-cover;

    >.bg-overlay {
        background-image: url("../../images/cover-pattern.png");
        background-position: center;
        background-size: cover;
        opacity: 1;
        background-color: transparent;
    }

    .footer {
        color: rgba($white, .5);
    }
}

//passowrd validations 
#password-contain {
    display: none;

    p {
        padding-left: 13px;

        &.valid {
            color: $success;

            &::before {
                position: relative;
                left: -8px;
                content: "✔";
            }
        }

        &.invalid {
            color: $danger;

            &::before {
                position: relative;
                left: -8px;
                content: "✖";
            }
        }
    }
}

//additionals
.btn-primary {
    background-color: #EC1388;
    color: #fff;
}

.btn-primary:hover {
    background-color: #c41271;
    color: #fff;
}

.text-pink {
    color: #EC1388;
}

.footer_copyright {
    color: #fff;
}

.dz-card {
    box-shadow: none;
}

.dz-card-body {
    padding: 0px;
}

.dz-box {
    margin: 0 !important;
}

.cb-lbl {
    padding-left: 10px;
}

.login_input_control {
    border: none;
    border-bottom: 1px solid lightgrey;
    border-radius: 5px;
}

.login_label_control {
    font-weight: 500;
}

.login-card {
    box-shadow: 0 4px 8px rgba(56, 65, 74, 0.15)
}