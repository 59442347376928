.terms-container {
    padding: 0;
    background-color: #f4f4f4;
  }
  
  .header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    border-bottom: 1px solid #dadada;
  }
  
  .navigation-action {
    width: 10px;
    height: 10px;
    padding-left: 10px;
  }
  
  .header-title {
    margin-right: 60px;
    font-weight: 600;
    color: #000;
    font-size: 18px;
    padding-top: 18px;
  }
  
  .content {
    padding: 20px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #ec1a86;
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 20px;
    font-weight: bold;
    color: #ec1a86;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  