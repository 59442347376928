.tags-section {
  .tags {
    display: inline-block;
    background: #912a4e;
    color: white;
    padding: 3px 10px 3px 10px;
    border-radius: 15px;
    margin-right: 6px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  i {
    vertical-align: bottom;
  }
}

.roster-count-view{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: rgb(246, 154, 205);
  border-radius: 25px;
  
}

@media (min-width: 1600px) and (max-width: 2560px) {
  .custom-column {
    flex: 0 0 calc(16.66667% - 1rem);
    max-width: calc(16.66667% - 1rem);
    margin-right: 1rem; /* Add spacing between cards */
  }
  
  /* Remove margin for the last card in the row */
  .custom-column:last-child {
    margin-right: 0;
  }
}


/* styles.css */
.google-btn-custom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
}

.google-btn-custom .google-btn {
  width: 100%;
  text-align: center;
}


.team-box .team-cover img, .profile-offcanvas .team-cover img, .modal-team-cover img {
  height: 90px;
  width: 100%;
  object-fit: cover;
}

//Job trip bands

.static_unpaid {
  border-left: 1rem solid #ff0000 !important;
  padding: 0 1.3rem 0 5px;
}

.static_partial {
  border-left: 1rem solid #ff7f50 !important;
  padding: 0 1.3rem 0 5px;
}

.static_paid {
  border-left: 1rem solid #008000 !important;
  padding: 0 1.3rem 0 5px;
}

.static_new {
  border-left: 1rem solid #ff0000 !important;
  padding: 0 1.3rem 0 5px;
}

.static_confirm {
  border-left: 1rem solid #008000 !important;
  padding: 0 1.3rem 0 5px;
}

.static_complete {
  border-left: 1rem solid #000000 !important;
  padding: 0 1.3rem 0 5px;
}

.event_border_left_unpaid {
  border-left: 1rem solid #ff0000 !important;
}

.event_border_left_partial {
  border-left: 1rem solid #ff7f50 !important;
}

.event_border_left_paid {
  border-left: 1rem solid #008000 !important;
}

.event_border_right_new {
  border-right: 1rem solid #ff0000 !important;
}

.event_border_right_confirm {
  border-right: 1rem solid #008000 !important;
}

.event_border_right_complete {
  border-right: 1rem solid #000000 !important;
}

//end
.date-color {
  color: #d9424d;
  float: right;
}

.edit-icon {
  float: right;
  width: fit-content;

  .show_update_job_detail_btn:hover {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset;
  }
}

.ml-1 {
  margin-left: 10px;
}

.pd-field {
  padding: 5px 0px;
}

.btn-light {
  background-color: #f5f5f5;
  border-color: #ddd;
}

.collapse-section {
  padding: 5px;
  background-color: #eef7fb;
}

.header-elements-inline {
  //display:flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 0px;
  border: 1px solid #d2d2d2;

  .txt-white {
    color: #fff;
  }

  .page-title {
    padding: 8px 0 8px 5px;
    position: relative;
    width: 100%;
  }

  .align-center {
    align-items: center;
  }

  .d-flex {
    display: flex;
  }

  span {
    margin-left: 10px;
  }
}

.align-inherit {
  vertical-align: inherit !important;
}

.btn:disabled {
  color: #5e5e5e;
  border: solid 1px #f7f8f9;
}

.contact-sec-top {
  padding: 5px 10px;
}

.contact-sec-bottom {
  padding: 5px 10px;
  background-color: #eef7fb;
}

.edit_row {
  background-color: #eff7fb;
}

.pac-container {
  z-index: 9999 !important;
}

.current_opportunity {
  border-left: 4px solid #03a9f4;
  background-color: #eef7fb;
}

.top_search_list {
  position: absolute;
  left: 2.8rem;
}

.search-list-dropdown {
  width: 251px;
  max-height: 300px;
  overflow-y: scroll;
  top: 2rem;
}

.brown-button {
  background-color: #912a4e;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.from-label {
  color: blue;
  /* Add any other styles you want for the "From" label */
}

.close-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.StripeElement {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.payment-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  background-color: #f44336;
  color: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripe-title-1 {
  text-align: center;
  margin: 15px 0px 25px 0px;
}

.balance-input {
  margin: 15px 0px 20px 0px;
}

.stripe_email {
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
}

.scriptdiv {
  padding: 10px;
  border: 1px solid #78828c21;
  border-left: 5px solid #ff6849;

  font-size: 12px;
}

//Full Calendar
.fc-scrollgrid-section-header {
  background-color: #012640;
  color: white;
  /* Optional: Change text color to white for better visibility */
}

.fc-timeline-header {
  color: #fff;
}

.fc-direction-ltr .fc-timeline-slot {
  border-left: 0px !important;
}

.fc-resource-group {
  background-color: #171e27;
}

.fc .fc-timeline-slot-minor {
  border-style: none !important;
}

.fc .fc-timeline-slot-cushion {
  color: white;
}

.fc-time-title {
  font-size: 0.85em;
}

.static-unpaid {
  border-left: 1rem solid #ff0000 !important;
  padding: 0 1.3rem 0 5px;
}

.static-partial {
  border-left: 1rem solid #ff7f50 !important;
  padding: 0 1.3rem 0 5px;
}

.static-paid {
  border-left: 1rem solid #008000 !important;
  padding: 0 1.3rem 0 5px;
}

.static_complete {
  border-left: 1rem solid #000000 !important;
  padding: 0 1.3rem 0 5px;
}

.eventTooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  /* Position the tooltip near the event */
  left: 0;
  top: 50%;
  /* Adjust this value to position the tooltip correctly */
}

.header-profile-user {
  width: 60px;
}

.record_count {
  margin-left: 10px;
}

// Sales pipeline

.right-align-row td:not(:first-child) {
  text-align: right;
}

.right-align-center-align {
  text-align: right;
}

table.inventory th:first-child {
  width: 40%;
}

table.inventory th:nth-child(2) {
  width: 12%;
}

table.inventory th:nth-child(3) {
  width: 12%;
}

table.inventory th:nth-child(4) {
  width: 10%;
}

table.inventory th:nth-child(5) {
  width: 12%;
}

table.inventory th:nth-child(6) {
  width: 14%;
}

table.inventory td:nth-child(1) {
  width: 40%;
}

table.inventory td:nth-child(2) {
  width: 12%;
}

table.inventory td:nth-child(3) {
  width: 12%;
}

table.inventory td:nth-child(4) {
  width: 10%;
}

table.inventory td:nth-child(5) {
  // text-align: right;
  width: 12%;
}

table.inventory td:nth-child(6) {
  text-align: right;
  width: 14%;
}

#chat-widget-container {
  bottom: 45px !important;
  right: 10px !important;
}

.ql-snow .ql-editor a {
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 3px;
  line-height: 1;
  transition: background-color 0.3s;
  display: inline-block;
}

.ck-balloon-panel,
.ck {
  z-index: 10001 !important;
}




//REGISTER PAGE 


.custom-col {
  background-color: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.logo-mobile{
  display:none;
}

.logo-ipad{
  display:none;
}

.full-height-card {
  height: 66vh;
  background: none;
  box-shadow: none;
}



.register-page{
  padding:10% 10%;
}

.font-mobile{
font-size: 40px;
font-Weight:"bold";
font-Family:"Poppins";
}
.font-subtitle{
  font-size: 20px;
  font-Family:"Poppins";
  text-Align:"center";
  font-Weight:"300";
  font-Family:"Poppins"
  }

  .auth-page-contents{
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-blend-mode: overlay; 
    background:  #de0378;
        background: linear-gradient( #ed3a99,
     #ea4c67);
  }


/* Small tablets (769px to 812px) */
@media (max-width: 769px)  {
  .auth-page-contents {
    background-color: white;
  }
  .bg-overlay {
    display: none;
    background-color: white;
  }

  .custom-col {
    background-color: white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .register-page {
    padding: 0;
  }
  .font-subtitle {
    font-size: 16px;
    font-family: "Poppins";
    text-align: center;
    font-weight: 300;
  }
  .font-mobile {
    font-size: 18px;
    font-weight: 400;
    font-family: "Poppins";
  }
  .logo-mobile {
    display: block;
  }
  .logo-ipad {
    display: none;
  }

 }

/* Tablets and small laptops (813px to 1024px) */
@media (min-width: 813px) and (max-width: 1024px) {
  .auth-page-contents {
    background-color: white;
  }
  .bg-overlay {
    display: none;
    background-color: white;
  }

  .custom-col {
    background-color: white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .register-page {
    padding: 0;
  }
  .font-subtitle {
    font-size: 16px;
    font-family: "Poppins";
    text-align: center;
    font-weight: 300;
  }
  .font-mobile {
    font-size: 20px;
    font-weight: 400;
    font-family: "Poppins";
  }
  .logo-mobile {
    display: block;
  }
  .logo-ipad {
    display: none;
  }

}

/* Laptops and desktops (1025px to 1600px) */
@media (min-width: 1025px) and (max-width: 1600px) {
  .custom-col {
    background-color: white;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  
  .reg-height {
    height: calc(100vh - 50px);
  }
  .logo-ipad {
    display: none;
  }
  .logo-mobile {
    display: none;
  }
  .font-subtitle {
    font-size: 20px;
    font-family: "Poppins";
    text-align: center;
    font-weight: 300;
  }
  .font-mobile {
    font-size: 30px;
    font-weight: 405;
    font-family: "Poppins";
  }
}

/* Small desktops (up to 1080px) */
@media  (min-width: 810px) and  (max-width: 1080px) {
  .auth-page-contents {
    display: block;
    background: #de0378;
    background: linear-gradient(to right, #ed3a99,
    #f7627b,
    #de0378,
    #f0516c);
  }
  .bg-overlay {
    display: block;
    position: relative;
    background: #de0378;
    background: linear-gradient(to right, #ed3a99,
    #f7627b,
    #de0378,
    #f0516c);
  }

  .reg-height {
    height: calc(100vh - 250px);
  }
  .custom-col {
    background-color: white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .custom-send-button {
    background-color:#de0378;;
    color: white; /* Optional: Change text color for better contrast */
}
 .floating-logo-1{
  position: absolute;
  top: 50%;
  right: 30px
 }
  .register-page {
    padding: 0;
  }
  .font-subtitle {
    font-size: 16px;
    font-family: "Poppins";
    text-align: center;
    font-weight: 300;
  }
  .font-mobile {
    font-size: 25px;
    font-weight: 400;
    font-family: "Poppins";
  }
  .logo-mobile {
    display: block;
  }
  .logo-ipad {
    display: none;
  }

}
@media  (min-width: 800px) and  (max-width: 1280px) {
  .auth-page-contents {
    display: block;
    background:   #de0378;
    background: linear-gradient(to right, #ed3a99,
    #f7627b,
    #de0378,
    #f0516c);
  }
  .bg-overlay {
    display: block;
    position: relative;
    background: #de0378;
    background: linear-gradient(to right, #ed3a99,
    #f7627b,
    #de0378,
    #f0516c);
  }
  .reg-height {
    height: calc(100vh - 250px);
  }
  .custom-col {
    background-color: white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .register-page {
    padding: 0;
  }
  .font-subtitle {
    font-size: 16px;
    font-family: "Poppins";
    text-align: center;
    font-weight: 300;
  }
  .font-mobile {
    font-size: 25px;
    font-weight: 400;
    font-family: "Poppins";
  }
  .logo-mobile {
    display: block;
  }
  .logo-ipad {
    display: none;
  }

}

//////
.datalist-ul {
  list-style: none;
  padding: 0;
  max-height: 150px;
  /* Set the maximum height you desire */
  overflow-y: auto;
  /* Add a vertical scrollbar if necessary */
  border: 1px solid #ccc;
  /* Add a border for styling */
  border-radius: 5px;
}

.datalist-li {
  padding: 5px 10px;
  cursor: pointer;
  color: #e5e5e5;
  background-color: #202124;
}

.gradient-pink {
  background: linear-gradient(to right, #ed3a99,
  #e76b80,
  #ec1388,
  #f15e77);
  background-size: 300% 100%;
  opacity: 0.9;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.gradient-pink:hover {
  background-position: 100% 0;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.btn-signup {
  background-image: linear-gradient(
    to right,
    #ed3a99,
    #e76b80,
    #ec1388,
    #f15e77
  );
  //background-image: linear-gradient(to right, #ed3a99, #e76b80, #EC1388, #f15e77);
  // background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  box-shadow: 0 2px 10px 0 #fea6cf;
  background-size: 300% 100%;
  opacity: 0.9;
  transition: all 0.5s ease-in-out;
  moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.btn-signup:hover {
  background-position: 100% 0;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  /* Move the background to the right bottom on hover */
}

/* CSS to style the events in FullCalendar */
.fc-day.fc-daygrid-day{
  cursor: pointer;
  height: 11rem;
}

.fc-day.fc-daygrid-day:hover{
  background-color: #fbfbfb!important;
}

.fc-day.fc-day-today.fc-daygrid-day{
  background-color: #fbfbfb!important;
  .fc-event{
    background-color: #f39dc0!important;
    color: #252525!important;

  }
}

.fc-day.fc-day-today.fc-daygrid-day:hover{
  background-color: #fbfbfb!important;
  .fc-event{
    background-color: #f39dc0!important;
    color: #000!important;
  }
}

.fc-day.fc-daygrid-day:hover .fc-event-main {
  background-color: #f5f5f5 !important;
  color: #000 !important;
    p{
      color: #000 !important;
    }
    .fw-semibold.text-danger.mt-2{
      color: red !important;
    }
}
.fc-day.fc-day-today.fc-daygrid-day:hover .fc-event-main {
  background-color: #f39dc0 !important;
  color: #e6e6e6 !important;
    .fw-semibold.text-danger.mt-2{
      color: red !important;
    }
}
// past
.fc-day.fc-day-past.fc-daygrid-day:hover{
  background-color: #fff!important;
  .fc-event{
    background-color: #f5f5f5!important;

  }
}
.fc-event.fc-event-past,.fc-event.fc-event-past .fc-event-main {
  background-color: #f5f5f5!important;
  color: gray!important;
}
// future
.fc-day.fc-day-future.fc-daygrid-day{
  background-color: #fff!important;
  .fc-event{
    background-color: #fff!important;

  }
}
.fc-day.fc-day-future.fc-daygrid-day:hover{
  background-color: #fbfbfb!important;
  .fc-event,.fc-event-main{
    background-color: #f39dc0!important;
  }
}

.fc-event.fc-event-future {
  background-color: #f672a7;
  color: white;
  p{
    color: black!important;
  }
  .fw-semibold.text-danger.mt-2{
    color: red!important;
  }
}
// .fc-event.fc-event-future:hover ,.fc-event.fc-event-future .fc-event-main:hover {
//   background-color: #fbfbfb!important;
//   color: black!important;
// }
// .fc-day.fc-day-future.fc-day-other.fc-daygrid-day:hover {
//   background-color: #fbfbfb!important;
// }
// today
.fc .fc-daygrid-day.fc-day-today {
  background-color: #fac9dc!important;
}

.fc-event.fc-event-today .fc-event-main {
  background-color: #fef1f6!important;
  color: black!important;
}
.fc-event.fc-event-today{
  color: rgb(168, 168, 168)!important;
}

/* Hover effect for events */
.event-title {
  background-color: #f26194;
  /* Set the background color */
  color: #fff;
  /* Set the text color */
  // border: 1px solid #461a31;
  /* Set the border color */
  padding: 5px;
  /* Add padding for spacing */
}

/* Hover effect for event title */
.event-title:hover {
  background-color: #f1417e;
  /* Set the background color on hover */
}

/* Style the event description */
.event-description {
  background-color: #ffd8e2;
  /* Set a lighter background color */
  color: #404040;
  /* Set the text color */
  padding: 5px;
  /* Add padding for spacing */
}


.imagesearch{

  opacity: 0.4;
}

.imagesearch img {
  height: 400px;
}


// .fc-h-event {
//   background-color: #fbfbfb !important;
// }

.fc-spacerButton-button {
  background-color: transparent !important;
  border: none !important;
}

.fc-view-button {
  background-color: transparent !important;
  border: none !important;
}

.text-gray {
  color: #495057;
}
.border-25{
  border-radius: 25px;
}
.calendar-hr{
  border-top: 0.5px solid #bcbcbc;
}
/* Button component */
.btn-emp-num {
  position: relative;
  width: 100%;
  padding: 15px 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  // box-shadow: 3px 3px 10px -6px #fea6cf;
}
.btn-start-shift{
  background-color: #1ed3ae;
  padding: 8px;
  border: 1px solid #1ed3ae;
}
.btn-start-shift:hover{
  background-color: #06c69f;
  border: 1px solid #06c69f;
}
.btn-start-break{
  padding: 8px;
  background-color: #f06e9c;
  border: 1px solid #f06e9c;
}

.btn-start-break:hover{
  background-color: #ec1388;
   border: 1px solid #ec1388;
}
.btn-end-break{
  padding:8px;
  background-color: #f06e9c;
  border: 1px solid #f06e9c;
}
.btn-end-break:hover{

  background-color: #ec1388;
  border: 1px solid #ec1388;
}
.shift-status-bar{
  background-color: #f06e9c;
    color: #fff;
}
.btn-end-shift{
padding:8px;
}
// /* Effect one */
// .btn-emp-num.type1::after,
.btn-emp-num.type1::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 100%;
  border: 2px solid;
  transition: all 0.6s ease;
  border-radius: 2px;
  border-left: 3px solid #f06e9c;
}

// .btn-emp-num.type1::after {
//     top: 0;
//     right: 0;
//     border-bottom-color: transparent;
//     border-left-color: transparent;
//     border-top-color: #EC1388;
//     border-right-color: transparent;
// }

.btn-emp-num.type1::before {
  bottom: 0;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #f06e9c;
  border-left-color: #f06e9c;
}

/* Hover effect */
// .btn-emp-num.type1:hover::after,
.btn-emp-num.type1:hover::before {
  width: 100%;
  height: 0%;
}

.card-business {
  box-shadow: 3px 3px 10px -6px #fea6cf !important;
  padding: 0px;
}

.rounded-blue-bg {
  background-color: #80e4fd21;
}

.rounded-blue-bg:hover {
  box-shadow: 0 5px 10px #b5d0da85;
}

.selectedind {
  box-shadow: 0 5px 10px #94b6c385;
  background-color: #ddf6fb;
  border-bottom: 2px solid #64cbe1;
  // transition: ease-in-out 3s;
}
.react-confirm-alert-button-group {
  justify-content: flex-end !important;
}

.password-input.is-invalid {
  background-image: none !important;
}

// available modal
.modal-content {
  .form-select {
    padding: 0.5rem 1.7rem 0.5rem 0.9rem !important;
  }
}

.showtiming {
  border: 0px !important;
  background-color: #fff !important;
}

.text-center-last {
  text-align-last: center;
}
.text-d-primary {
  color: #f672a7;;
}
.fs-25 {
  font-size: 25px;
}
.job-title:hover {
  color: #f672a7 !important;
}
.joblist-card-radius {
  border-radius: 15px !important;
}
.joblist-card-bottom-radius {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.joblist-card-top-radius {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.job-search-btn {
  //   top: -35px;
  float: right;
  padding: 5px 30px 5px 30px;
  border-radius: 7px;
  left: 0px;
}
.w-78{
    width: 78% !important;
}
.w-60{
  width: 60% !important;
}
.w-65{
  width: 65% !important;
}
.text-align-justify{
  text-align: justify;
}
.rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  .filter-br.accordion-item:last-of-type .accordion-button.collapsed{
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .filter-br{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
.filter-br-top{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
}
.filter-br-bottom{
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.card-hover-shadow {
    transition: box-shadow 0.3s ease-in-out;
}

.card-hover-shadow:hover {
    box-shadow: 0 14px 40px rgba(30,10,58,.1);
}

.ver-line::before {
  content: "";
  position: absolute;
  height: 14px;
  border: none;
  width: 1px;
  border-radius: 1px;
  background: #e7e7f1;
  left: -10px;
  margin-right: 0;
  top: 2px;
}

/* Hide scrollbar for Employee Reviews */
.scrollable-div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollable-div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overview-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.overview-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f672a7; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded scrollbar */
}

.overview-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(171, 171, 171, 0.1); /* Scrollbar track color */
  border-radius: 10px;
}
.top-skills-list-item:hover .fs-13.mb-0.listname.fw-normal.cursor-pointer.text-black {
  color: #f672a7 !important;
}

.industry,
.skills {
  .accordion-button:not(.collapsed){
    background-color: rgba(0, 0, 0, 0.05)!important;
  }
}


.shift-clock-icon {
  background-color: #ffd2e3;
  border: 1px solid palevioletred;
  padding: 5px 8px;
  border-radius: 2px;
}
.shift-clock-icon i{
  color: rgb(250, 2, 143);
}
.search-location-text{
  color: #272727;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}


.search-text-btn {
  background: #EC1388;
  border-radius: 25px;
  border: none;
  height: 40px;
  color: #fff;
}

.search-text-icon{
  font-size: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff !important;
  padding: 6px;
}

.description-container img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures image is not cropped */
  max-height: 200px; /* Adjust as needed for your layout */
}

//Skill chips

.skill_set_area {
  margin: 15px 0px;
  height: 60px; /* Set a fixed height */
  overflow-y: scroll; /* Enable vertical scrolling if content exceeds the height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  -ms-overflow-style: none;  /* Hide the scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide the scrollbar for Firefox */
}


.profile_box_card{
  -webkit-box-shadow: 0px 11px 10px -8px rgba(0,0,0,0.08) !important;
-moz-box-shadow: 0px 11px 10px -8px rgba(0,0,0,0.08) !important;
box-shadow: 0px 11px 10px -8px rgba(0,0,0,0.08) !important;
border-radius: 25px;
}

//Overview
.status-badge{
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}


@media (max-width: 769px)  {
  .mobie-view{
    margin-bottom: 20px;
  }
  
 }

 @media (min-width: 813px) and (max-width: 1024px) {
  .mobie-view{
    margin-bottom: 20px;
  }
}

/* Laptops and desktops (1025px to 1600px) */
@media (min-width: 1025px) and (max-width: 1600px) {
  .mobie-view{
    margin-bottom: 0px;
  }

  .imagesearch{

    opacity: 0.4;
    height: 350px;
  }

}

/* Small desktops (up to 1080px) */
@media  (min-width: 810px) and  (max-width: 1080px) {
  .mobie-view{
    margin-bottom: 0px;
  }
}
@media  (min-width: 800px) and  (max-width: 1280px) {
  .mobie-view{
    margin-bottom: 20px;
  }
  .imagesearch{

    opacity: 0.4;
    height: 350px;
  }
  

}




.location_set_area{
  height: 30px;
  overflow-y: scroll; /* Enable vertical scrolling if content exceeds the height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  -ms-overflow-style: none;  /* Hide the scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  
}

.badge-soft-skills{
  color: #272727;
  background-color: #ffebfc;
  border-radius: 100px;
  padding: 7px 10px;
  font-weight: 400;
  display: inline-block;
  margin: 6px 0px;
}


.custom-modal .modal-content {
  border-radius: 10px;
}


//Add Talent 

.btn-talent{
  background-color: #EC1388;
color: #ffffff;
width: 100%;
margin: 10px 0px;
height: 45px;
font-size: 14px;
border-radius: 25px;
}

.btn-talent:hover{
  background-color: #f1eaff;
color: #272727;
}



.search-text-box{
  position: relative;   
}

.search-text-form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0px 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  background-color: #f6f6f6;
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 25px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #272727;
}



// .top-skills-list-item:hover {
//   background-color: var(--vz-accordion-active-bg);
// }


// select custom styling
// .css-b62m3t-container .css-1p3m7a8-multiValue {
//     border: 1px solid #c9b4ef !important;
//     background-color: #fff !important;
//     border-radius: 5px !important;
//   }
//   .css-b62m3t-container .css-wsp0cs-MultiValueGeneric {
//     color: #7037d6 !important;
//   }
//   .css-b62m3t-container .css-13cymwt-control {
//     background-color: #fff !important;
//     border-color: #c9b4ef !important;
//   }
//   .css-3w2yfm-ValueContainer:focus {
//     padding: 8px 8px;
//   }
//   .css-b62m3t-container .css-t3ipsp-control {
//     background-color: #fff !important;
//     border-color: #e1e1e1 !important;
//   }
  // .css-1nmdiq5-menu{
  //   background-color: #fff !important;
  //   border: none;
  // }

// .ver-line.inside::before{
//     display: inline-block;
//   color: #717b9e;
//   font-size: 16px;
//   padding-right: 0;
//   margin-right: 5px;
//   width: 16px;
//   height: 16px;
//   position: relative;
//   top: 2px;
// }
//profile view stylings
.image-fluid {
  max-width: 100%;
  height: 100%;
}

.vstack.cursor-pointer.delete-photo:hover {
  color: #ee6464;
}
.vstack.cursor-pointer.add-photo:hover {
  color: #f672a7;
}

.education-container {
  border-left: 4px solid pink;
  padding-left: 10px;
}

.certificate-container {
  border-left: 4px solid #f672a7;
  padding-left: 10px;
}

.location-container {
  border-left: 4px solid rgb(0, 187, 41);
  padding-left: 10px;
}
.swiper {
  display: block;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
}
.swiper-wrapper {
  box-sizing: initial;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease;
  transition-timing-function: var(
    --swiper-wrapper-transition-timing-function,
    initial
  );
  width: 100%;
  z-index: 1;
}
#appliedJobList{
  .border.border-dashed.border-end-0.border-start-0.card-body{
    border-style: none !important;
  }
}
#unavailabilityModal{
  .modal-content{
    width: 70%!important;
  }
}
.modal-body.unavailability_calender{
  padding-bottom: 0;

  #datepicker-publish-input{
    display: none;
  }

  .flatpickr-calendar.animate.inline{
    width: 19rem !important;
    // margin: auto!important;
  }

  .flatpickr-calendar.inline .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day {
    max-width: 13% !important;
    border-radius: 25px!important;
    margin: 2px 0;
  }

}

// overview flatpickr
  #datepicker-overview-input{
    display: none;
  }

#collapse_volunteer_availability,#collapse_availability{
  .row {
    .col-md-3{
      padding-right: 6rem!important;
    }
  }
  select.form-select {
    padding: 0.5rem 2rem 0.5rem 0.9rem!important;
    font-size: 0.725rem!important;
  }
}

.w-100 .overview-day-card:hover {
  background-color: #fef1f6; /* Change to your desired soft background color */
}

.w-100 .overview-open-card:hover {
  background-color: #eaf5fb; /* Change to your desired soft background color */
}