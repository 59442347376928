//
// Dropzone
//


.dropzone {
  max-height: 100px;
  border: 2px dashed pink;
  background: var(--#{$prefix}card-bg-custom);
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    margin: 1em 0;
  }
}

.logo-dropzone {
  max-height: 150px !important;
  max-width: 500px;
}

.logo-dropzone:hover {
  border: 2px dashed #e659a4 !important;
  transition: 1s ease-in-out;
}