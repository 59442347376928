
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: #fff;
}

.fc .fc-toolbar .btn {
    text-transform: capitalize;
}

.fc-dayGridMonth-view .fc-daygrid-day-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0;
  color: #212529;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 2px;
}

.fc-dayGridMonth-view .fc-day{
  border-color: #e6e4e4
}


.fc-dayGridMonth-view .fc-col-header-cell-cushion {
  color: #252525;
  background-color:white;
  border:none;
}

.fc-dayGridMonth-view .fc-day {
  border-color: none; /* Change the border color */
}

.fc {
  .fc-toolbar {
      h2 {
          font-size: 16px;
          line-height: 30px;
          text-transform: uppercase;
      }

      @media (max-width: 767.98px) {

          .fc-left,
          .fc-right,
          .fc-center {
              float: none;
              display: block;
              text-align: center;
              clear: both;
              margin: 10px 0;
          }

          >*>* {
              float: none;
          }

          .fc-today-button {
              display: none;
          }
      }

      .btn {
          text-transform: capitalize;
      }
  }

  .fc-col-header-cell {
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }

  .fc-col-header-cell-cushion {
      display: block;
      padding: 8px 4px;
  }

  .fc-daygrid-day-number {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight:2px;
      margin: 2px;
  }

  .fc-daygrid-day {
      &.fc-day-today {
          background-color: rgba(128, 0, 255, 0.11);
  
          .fc-daygrid-day-number {
            background-color: #f672a7;
            color: #fff;
          }
      }
     
  }

  .fc-daygrid-day {
    //   &.fc-day-today {
    //     //   background-color: rgba(rgb(154, 152, 157), 0.1);
    //   }
      
  }

  .fc-daygrid-day {
    &.fc-day-past {
        background-color: rgba(rgb(154, 152, 157), 0.1);
    }
    
    
}
  


.fc-dayGridMonth-view .fc-daygrid-day.fc-day-future {
  color: black; /* Change the color for future dates */
}


  // .fc-timegrid-col {
      // &.fc-day-today {
      //     background-color: rgba($primary, 0.1);
      // }
  // }

  .fc-list-event:hover td {
      background: transparent;
  }

  .event_background{
    overflow:hidden;

  }

  .fc-list-event-title {
      a {
          color: #fff !important;
      }
  }

  .fc-col-header,
  .fc-daygrid-body,
  .fc-scrollgrid-sync-table {
      width: 100% !important;
  }

  .fc-toolbar-chunk .fc-today-button {
    background-color: #865ce2 !important;
    border: #865ce2;
  }

  .fc-icon-chevron-left::before {
    content: ""!important;
  }
  .fc-icon-chevron-right::before {
    content: ""!important;
  }
  .fc-toolbar-chunk .btn-group .btn:hover, .fc-toolbar-chunk .btn-group .btn.active {
    color: #fff;
    background-color: #865ce2;
  }
  .fc-prev-button, .fc-next-button {
    position: relative;
    height: 37.5px;
    width: 37.5px;
  }
  .fc-daygrid-dot-event:hover {
    background-color: #7037d6;
    color: #fff;
  }
  // .fc-scrollgrid-section>*{
  //     border-left: $table-border-width solid $table-border-color;
  //     border-top: $table-border-width solid $table-border-color;
  // }

  .fc-scrollgrid-section-liquid>td{
      border-top: 0;
  }
}